<template>
  <v-skeleton-loader
    v-if="!contact"
    type="list-item-avatar"
    class="header"
  />
  <div v-else>
    <div class="d-flex flex-row align-center">
      <document-name-ref
        class="mr-2"
        :id="topic.id"
      />
    </div>

  </div>
</template>

<script>

export default {
  components: {
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  mounted () {
  },
  activated () {
  },
  watch: {
  },
  computed: {
    topic () {
      const c = this.document ?? this.defaultContact
      return c
    }
  },
  data () {
    return {
      defaultTopic: {
        title: 'Default Topic'
      }
    }
  },
  methods: {
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.header
  width 400px
</style>
